/* eslint-disable react/jsx-no-bind */
/* eslint-disable */
/* stylelint-disable */
import { PureComponent, Suspense } from 'react';
import {
    ProductPageComponent as SourceProductPageComponent,
} from "SourceRoute/ProductPage/ProductPage.component";
import TextPlaceholder from 'Component/TextPlaceholder';
import { lowPriorityLazy } from 'Util/Request/LowPriorityRender';
import { ProductPageTabs } from 'Route/ProductPage/ProductPage.config';
import ContentWrapper from 'Component/ContentWrapper';
import NoMatchHandler from 'Route/NoMatchHandler';
import Footer from 'Component/Footer';
export const ProductActions = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "overlays" */
    'Component/ProductActions'
));

import './ProductPage.override.style';

/** @namespace Route/ProductPage/Component */
export class ProductPageComponent extends SourceProductPageComponent {
    tabMap = {
        [ ProductPageTabs.INFORMATION ]: {
            name: __('Description'),
            shouldTabRender: () => {
                const { isInformationTabEmpty } = this.props;

                return !isInformationTabEmpty;
            },
            render: () => this.renderProductInformationTab(),
        },
        [ ProductPageTabs.ATTRIBUTES ]: {
            name: __('Specifications'),
            shouldTabRender: () => {
                const { isAttributesTabEmpty } = this.props;

                return !isAttributesTabEmpty;
            },
            render: () => this.renderProductAttributesTab(),
        },
        [ ProductPageTabs.REVIEWS ]: {
            name: __('Reviews'),
            shouldTabRender: () => {
                const { areReviewsEnabled } = this.props;

                return areReviewsEnabled;
            },
            render: () => this.renderProductReviewsTab(),
        },
    };

    renderProductBrand() {
        const {
            dataSource: {
                attributes: { manufacturer: { attribute_value: manufacturer = '', attribute_options: manufacturer_options = '' } = {} } = {},
            },
        } = this.props;

        if (!manufacturer) {
            return null;
        }
        
        return (
            <>
                <meta itemProp="brand" content={ manufacturer_options[manufacturer].label ?? '' } />
                <h4 block="ProductPage" elem="Brand" itemProp="brand">
                    <TextPlaceholder content={ manufacturer_options[manufacturer].label ?? '' } />
                </h4>
            </>
        );
    }

    renderProductDesktopMainData() {
        return (
            <>
                { this.renderProductName() }
                { this.renderProductBrand() }
            </>
        );
    }

    renderProductActions() {
        const {
            getLink,
            dataSource,
            areDetailsLoaded,
            setActiveProduct,
            parameters,
            isMobile,
        } = this.props;

        return (
            <div block="ProductPage" elem="ProductActions">
                { this.renderProductDesktopMainData() }
                <Suspense fallback={ this.renderProductActionsPlaceholder() }>
                    <ProductActions
                      getLink={ getLink }
                      product={ dataSource }
                      parameters={ parameters }
                      areDetailsLoaded={ areDetailsLoaded }
                      setActiveProduct={ setActiveProduct }
                    />
                </Suspense>
            </div>
        );
    }
    render() {
        return (
            <NoMatchHandler>
                <main
                  block="ProductPage"
                  aria-label="Product page"
                  itemScope
                  itemType="http://schema.org/Product"
                >
                    <ContentWrapper
                      wrapperMix={ { block: 'ProductPage', elem: 'Wrapper' } }
                      label={ __('Main product details') }
                    >
                        { this.renderProductPageContent() }
                    </ContentWrapper>
                    { this.renderAdditionalSections() }
                    { this.renderReviewPopup() }
                    <Footer isVisibleOnMobile />
                </main>
            </NoMatchHandler>
        );
    }
}
export default ProductPageComponent;